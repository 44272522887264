<template>
    <div class="checkout">
        <div class="checkout-title">
            Checkout
        </div>
        <div class="checkout-content">
            <div class="checkout-content-title">
                <span>Shipping</span>
                <span @click="changeaAddress()">Edit</span>
            </div>

            <p>
                {{ address1.receiver }},
                {{ address1.contactEmail }}

            </p>
            <p>
                {{ address1.receiveAddress }}
                {{ address1.receiveAddress2 }}
            </p>
            <p>
                {{ address1.city }}
                {{ address1.province }}
                {{ address1.country }}
                {{ address1.continent }}
            </p>
            <p>
                {{ address1.postcode }}
            </p>
        </div>
        <div class="checkout-content">
            <div class="checkout-content-title">
                <span>Shipping Cart</span>
                <span @click="goShipCart()">Go Back</span>
            </div>
            <p>
                <span>Subtotal</span>
                <span>${{ (orderInfo.totalPrice -orderInfo.farePrice) }}</span>
            </p>
            <p>
                <span>Shipping Free</span>
                <span>${{ orderInfo.farePrice }}</span>
            </p>
            <p>
                <span>Tax</span>
                <span>$0</span>
            </p>
            <p style="font-weight: bold;color: black">
                <span>Order Total</span>
                <span>${{ orderInfo.totalPrice }}</span>
            </p>
        </div>
        <div class="checkout-content-1">
            <div>
                <span id="ITEM">ITEM</span>
                <span>QTY</span>
                <span>PRICE</span>
            </div>
            <div v-for="(item,index) in orderInfo.orderDetail" :key="index">
                <span>{{ item.productName }}</span>
                <span>{{ item.qty }}</span>
                <span>${{ item.price * item.qty }}</span>
            </div>
        </div>
        <div id="checkout-pay">

        </div>
    </div>

</template>

<script>
    import {defineComponent, ref} from "vue";

    import {useRoute, useRouter} from "vue-router";
    import axios from "@/api/axios";
    import Toast from "@/components/UI/Toast";
    import dateFormat from "@/utils/dateFormat";


    export default defineComponent({
        name: "checkout",
        data() {
            return {
                address1: {},
                orderNum1: '',
                clientId: '',
                clientToken: '',
                orderInfo: {},
            }
        },

        methods: {
            changeaAddress() {
                this.$router.replace("/address");
                // this.$router.replace("/paysuccess");
            },
            goShipCart() {
                this.$router.replace("/order");
            },
            loadJS() {
                const script = document.createElement('script');
                script.type = 'text/JavaScript';
                script.src = "https://www.paypal.com/sdk/js?components=buttons&enable-funding=venmo&client-id=" + this.clientId
                script.setAttribute('data-client-token', this.clientToken)
                // head.insertAdjacentHTML('afterbegin', `<script src='${script.src}' data-client-token="${this.clientToken}">`);
                document.body.appendChild(script)
            }
        },
        created() {
            this.orderNum = this.$route.query.orderNum;
            axios.get("/M/Paypal/Checkout/" + this.orderNum)
                .then((res) => {
                    if (res.data.success) {
                        this.address1 = res.data.obj.address;
                        this.clientId = res.data.obj.client_id;
                        this.clientToken = res.data.obj.client_token;
                        this.orderInfo = res.data.obj.order;
                        // 组件内获取paypal JS
                        this.loadJS();
                        Toast({
                            type: 'success',
                            title: 'Paypal loading...',
                        });

                    } else {
                        Toast({
                            type: "error",
                            title: res.data.msg,
                        });
                    }
                })
                .catch((e) => {
                    console.log(e)
                });
        },
        mounted() {
            const orderNum = this.orderNum;
            let paypalSetnum = 0;
            const paypalSet = setInterval(function () {
                paypalSetnum++;
                if (window.paypal) {
                    clearInterval(paypalSet);
                    setTimeout(function () {
                        Toast({
                            type: "success",
                            title: 'Paypal loaded!',
                            duration: 1000,
                        });
                    });
                    window.paypal.Buttons(
                        {
                            createOrder: function (data, actions) {
                                // 付款金额前端提交
                                // return actions.order.create({
                                //   // eslint-disable-next-line @typescript-eslint/camelcase
                                //   purchase_units: [{
                                //     amount: {
                                //       value: amount
                                //     }
                                //   }],
                                // });
                                return fetch(`/M/Paypal/getorders?type=button&orderNum=${orderNum}`, {
                                    method: "post",
                                    // use the "body" param to optionally pass additional order information
                                    // like product ids or amount
                                }).then((response) => response.json()).then((order) => {
                                    return order.data.id
                                });
                            },
                            // 用户支付成功
                            onApprove: function (data, actions) {

                                return fetch('/M/Paypal/captureorder?type=button&id=' + data.orderID, {method: "post",})
                                    .then((response) => response.json())
                                    .then((orderData) => {
                                        // Successful capture! For dev/demo purposes:

                                      window.location.href = '/paysuccess';
                                      // this.$router.replace("/paysuccess");
                                    })


                            },
                            // 用户关闭支付
                            onCancel: function (data) {
                                console.log('用户关闭支付')
                            },
                            // 错误处理
                            // onError: function (err) {
                            //     console.log(err)
                            // },
                            // 第一次初始化
                            onInit: function (data, actions) {
                                console.log(data, actions)
                            }
                        }
                    ).render('#checkout-pay');
                }
                // 3S没有获取paypal
                if (paypalSetnum > 5) {
                    clearInterval(paypalSet);
                    Toast({
                        type: "error",
                        duration: 4000,
                        title: 'Paypal loading failed, please refresh the page or contact the administrator'
                    });
                }
            }, 600);
        },
        setup() {

            const postageFee = ref();
            const order = ref(null);
            const address = ref(null);
            const list = ref([]);
            const route = useRoute();
            const router = useRouter();
            const orderNum = ref(route.query.orderNum);
            const expendSwitch = ref([true, true, false]);
            return {
                order,
                address,
                dateFormat,
                list,
                orderNum,
                expendSwitch,
                postageFee,
            };
        },
    });

</script>

<style scoped>

    .checkout {
        padding: 0 10px;
        font-size: 16px;
    }

    .checkout-title {
        font-weight: bold;
        font-size: 24px;
        padding-bottom: 5px;
    }

    .checkout-content {
        padding: 5px 0;
        border-top: 1px solid #eee;

    }

    .checkout-content-1 {
        padding: 5px 0;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin-bottom: 280px;
    }

    .checkout-content-1 > div {
        display: flex;
        width: 95%;
        margin: 0 auto;
        justify-content: space-between;
        padding: 5px 0;
        font-weight: bold;
        text-align: center;
        font-size: 14px;
    }

    .checkout-content-1 > div > span:nth-child(1) {
        width: 70%;
    }

    .checkout-content-1 > div > span:nth-child(2) {
        width: 15%;
    }

    .checkout-content-1 > div > span:nth-child(3) {
        width: 15%;
    }

    .checkout-content-1 > div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        color: #999;
        padding: 10px 0;
        border-bottom: 1px solid #eee;
        font-weight: unset;
    }

    .checkout-content-title {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
    }

    .checkout-content-title > span:nth-child(1) {
        font-weight: bold;
    }

    .checkout-content-title > span:nth-child(2) {
        font-weight: bold;
        color: #df0024;
    }

    .checkout-content p {
        color: #666;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
    }

    .checkout-pay p {
        text-align: center;
        background: #df0024;
        width: 95%;
        margin: 10px auto;
        height: 40px;
        line-height: 40px;
        color: white;
        border-radius: 5px;
    }

    #checkout-pay {
        position: fixed;
        width: 95%;
        bottom: 45px;
        background: white;
    }
</style>